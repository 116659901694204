import React from "react"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import Layout from "../components/layout"
import SEO from "../components/seo"

const IndexPage = ({ data }) => (
  <Layout>
    <SEO title="Home" />
    <div dangerouslySetInnerHTML={{ __html: data.strapiHomePage.HTML }}></div>
  </Layout>
)
IndexPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default IndexPage

export const pageQuery = graphql`
  query HomeQuery {
    strapiHomePage {
      HTML
      id
    }
  }
`
